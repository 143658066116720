export const BOOK_STATUS_CODES = {
  bookCreated: "BOOKCREATED",
  bookUploading: "BOOKUPLOADING",
  bookProcessing: "BOOKPROCESSING",
  bookExtFailed: "BOOKEXTFAILED",
  bookUploaded: "BOOKUPLOADED",
  bookVerified: "BOOKVERIFIED",
  chapterGrouped: "CHAPTERGROUPED",
  bookRecording: "BOOKRECORDING",
  audioMerging: "AUDIOMERGING",
  audioMergeFailed: "AUDIOMERGEFAILED",
  bookRecorded: "BOOKRECORDED",
  audioOptimized: "AUDIOOPTIMIZED",
  readyForProduction: "READYFORPRODUCTION",
  completed: "COMPLETED",
};
