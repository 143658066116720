import React, { Fragment, useEffect } from "react";
import {
  Grid,
  Card,
  CardMedia,
  CardActions,
  Button,
  Typography,
  IconButton,
  CardHeader,
  CardContent,
  Box,
} from "@mui/material";
import SyncIcon from "@mui/icons-material/Sync";
import { basetheme } from "components/common/Theme";
import AddIcon from "@material-ui/icons/Add";
import { useStateUpdate } from "UseStateUpdate";
import { useNavigate } from "react-router-dom";
import { COLORS } from "constants";
import { BOOK_STATUS_CODES as BookStatusCodes } from "constants";
import { ThreeDots } from "react-loader-spinner";

function BookDetailCard(props) {
  const {
    addNewBook,
    bookDetails,
    fetchProjectDetails,
    openCreateBookModal,
    openUploadBookModal,
    projectStatus,
    isRefreshing,
    openAssignAuthorModal,
    currentBook,
  } = props;
  const navigate = useNavigate();
  const isHovered = useStateUpdate(false);
  const isButtonEnabled = useStateUpdate(false);
  const bookStatus = useStateUpdate({});

  useEffect(() => {
    if (addNewBook) {
      isButtonEnabled.update(true);
    } else {
      bookStatus.update(bookDetails.state.status);
      isButtonEnabled.update(
        !(
          bookStatus.state.statusCode === BookStatusCodes.bookUploading ||
          bookStatus.state.statusCode === BookStatusCodes.bookProcessing ||
          bookStatus.state.statusCode === BookStatusCodes.bookExtFailed ||
          bookStatus.state.statusCode === BookStatusCodes.audioMerging ||
          bookStatus.state.statusCode === BookStatusCodes.audioMergeFailed
        )
      );
    }
  }, [addNewBook, bookStatus.state]);

  const checkBookStatus = () => {
    const { bookId } = bookDetails.state;
    const { statusCode } = bookStatus.state;

    const navigationMap = {
      [BookStatusCodes.bookCreated]: () => openUploadBookModal.update(true),
      [BookStatusCodes.bookUploaded]: () => navigate(`/verifybook/${bookId}`),
      [BookStatusCodes.bookVerified]: () =>
        navigate(`/book-grouping/${bookId}`),
      [BookStatusCodes.chapterGrouped]: () => {
        openAssignAuthorModal.update(true);
        currentBook.update(bookDetails.state);
      },
      [BookStatusCodes.bookRecording]: () =>
        navigate(`/recording-status/${bookId}`),
      [BookStatusCodes.bookRecorded]: () =>
        navigate(`/optimize-audio/${bookId}`),
      [BookStatusCodes.audioOptimized]: () =>
        navigate(`/optimized-audio/${bookId}`),
      [BookStatusCodes.readyForProduction]: () =>
        navigate(`/ready-for-production/${bookId}`),
      [BookStatusCodes.completed]: () => navigate(`/completed-book/${bookId}`),
    };

    const navigateTo = navigationMap[statusCode];
    if (navigateTo) {
      navigateTo();
    }
  };

  const handleReassignAuthor = () => {
    openAssignAuthorModal.update(true);
    currentBook.update(bookDetails.state);
  };

  const getBookStatus = () => {
    const statusCode = bookStatus.state.statusCode;
    const statusOptions = {
      [BookStatusCodes.bookCreated]: "Upload book",
      [BookStatusCodes.bookUploading]: "Book uploading",
      [BookStatusCodes.bookProcessing]: "Book processing",
      [BookStatusCodes.bookExtFailed]: "Book processing failed",
      [BookStatusCodes.bookUploaded]: "Verify book",
      [BookStatusCodes.bookVerified]: "Group chapters",
      [BookStatusCodes.chapterGrouped]: "Assign authors",
      [BookStatusCodes.bookRecording]: "View recording status",
      [BookStatusCodes.bookRecorded]: "Optimize audio",
      [BookStatusCodes.audioMerging]: "Audio merging",
      [BookStatusCodes.audioMergeFailed]: "Audio merge failed",
      [BookStatusCodes.audioOptimized]: "Verify optimized audio",
      [BookStatusCodes.readyForProduction]: "Check ready for production",
      [BookStatusCodes.completed]: "View book",
    };

    return statusOptions[statusCode] || "";
  };

  const backgroundColor = () => {
    let color = COLORS.aliceBlue;
    if (isHovered.state) color = basetheme.palette.primary.main;
    if (!isButtonEnabled.state) color = COLORS.gray61;
    return color;
  };

  const buttonFontColor = () => {
    let color = COLORS.aliceBlue;
    if (!isHovered.state) color = "primary.main";
    if (!isButtonEnabled.state) color = COLORS.white;
    return color;
  };

  return (
    <Grid item xs={12} md={6} container alignItems="stretch">
      <Card sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        {addNewBook ? (
          !isRefreshing.state && (
            <CardMedia
              component="svg"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <AddIcon style={{ color: basetheme.palette.primary.main }} />
            </CardMedia>
          )
        ) : (
          <Fragment>
            <CardActions sx={{ justifyContent: "flex-end" }}>
              {bookStatus.state.statusCode ===
                BookStatusCodes.bookRecording && (
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={handleReassignAuthor}
                >
                  <Typography variant="font12b">Reassign author</Typography>
                </Button>
              )}
              <IconButton onClick={fetchProjectDetails}>
                <SyncIcon sx={{ color: basetheme.palette.primary.main }} />
              </IconButton>
            </CardActions>
            <CardHeader
              title={bookDetails.state.bookName}
              titleTypographyProps={{ sx: { color: "primary.main" } }}
              subheader={`Author: ${bookDetails.state.authorName}`}
              subheaderTypographyProps={{ sx: { textAlign: "justify" } }}
              sx={{ marginX: 3 }}
            />
          </Fragment>
        )}
        <CardContent
          sx={{
            backgroundColor: backgroundColor(),
            marginTop: "auto",
            textAlign: "center",
            cursor:
              isButtonEnabled.state && !isRefreshing.state
                ? "pointer"
                : "no-drop",
          }}
          onMouseEnter={() => {
            isButtonEnabled.state && isHovered.update(true);
          }}
          onMouseLeave={() => {
            isHovered.update(false);
          }}
          onClick={() => {
            if (isButtonEnabled.state && !isRefreshing.state) {
              addNewBook ? openCreateBookModal.update(true) : checkBookStatus();
            }
          }}
        >
          {isRefreshing.state ? (
            <Box display="flex" justifyContent="center">
              <ThreeDots height="30" color={COLORS.primary} />
            </Box>
          ) : (
            <Typography variant="font20b" color={buttonFontColor()}>
              {addNewBook ? "Add book" : getBookStatus()}
            </Typography>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
}

export default BookDetailCard;
