import React, { Fragment, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import {
  Toolbar,
  IconButton,
  Container,
  Grid,
  Stack,
  Tooltip,
  Menu,
  MenuItem,
  ListItemIcon,
  Typography,
  useMediaQuery,
  Box,
  Badge,
  Link,
  Divider,
} from "@mui/material";
import { PermIdentityOutlined } from "@material-ui/icons";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";

import MenuIcon from "@mui/icons-material/Menu";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

import logo from "assets/images/logo.png";

import LogoutIcon from "@mui/icons-material/Logout";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

import { AppBar, menuProps, menuHover } from "components/common/Styles";
import { useStateUpdate } from "UseStateUpdate";
import { NOTIFICATION_MENU_WIDTH } from "constants/otherConstant";
import { NORMAL_PAGES, COLORS } from "constants";
import { useAuth, logout } from "service/AuthProvider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import {
  getUserName,
  useIsAdmin,
  useIsAuthorOrEngineer,
  useIsSuperAdmin,
} from "utils/GetUserDetail";
import PrimaryNotifications from "components/Notifications/PrimaryNotifications";
import { getNotifications } from "service/NotificationsService";
import { getUnReadThreads } from "service/ChatService";

const pages = NORMAL_PAGES;

function AppToolbar(props) {
  const navigate = useNavigate();
  const isAuthenticated = useAuth();
  const isAuthorOrEngineer = useIsAuthorOrEngineer();
  const { pathname } = useLocation();
  const classes = menuHover();
  const { open, isToolbarHidden } = props;
  const isSuperAdmin = useIsSuperAdmin();
  const isAdmin = useIsAdmin();
  const isMenuIcon = useStateUpdate(true);
  const profileMenu = useStateUpdate(null);
  const notificationMenu = useStateUpdate(null);
  const notifications = useStateUpdate(null);
  const isNewNotifications = useStateUpdate(false);
  const unReadThreads = useStateUpdate(0);

  useEffect(() => {
    fetchNotifications();
    const excludedPaths = [
      "/reset-password",
      "/verifybook/",
      "/book-grouping/",
      "/optimize-audio/",
      "/optimized-audio/",
      "/ready-for-production/",
      "/completed-book/",
      "/voices/",
      "/account/new-account-verification",
    ];

    const isExcluded =
      pages.includes(pathname) ||
      excludedPaths.some((path) => pathname.includes(path)) ||
      isAuthorOrEngineer;
    isMenuIcon.update(!isExcluded);
    callGetUnReadThreads();
  }, [pathname]);

  useEffect(() => {
    isToolbarHidden.update(false);
  }, [isMenuIcon.state]);

  useEffect(() => {
    notifications.state && checkIsNewNotifications();
  }, [notifications.state]);

  const logOutUser = async () => {
    logout();
    navigate("/login");
    window.location.reload();
  };

  const isSm = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const fetchNotifications = async () => {
    let listParams = `?limit=10&sort=DESC`;
    let result = await getNotifications(listParams);
    if (result.status === 200) {
      notifications.update(result.data.data.notifications);
    }
  };

  const callGetUnReadThreads = async () => {
    let result = await getUnReadThreads();
    if (result.status === 200) {
      unReadThreads.update(result.data.data.noOfUnreadThreads);
    }
  };

  const checkIsNewNotifications = () => {
    const newNotifications = notifications.state.some(
      (notification) => !notification.isRead
    );
    isNewNotifications.update(newNotifications);
  };

  const renderIcon = () => {
    return isMenuIcon.state ? (
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={() => open.update(!open.state)}
        edge="start"
        sx={{
          mr: 2,
          display: open.state && { xs: "none", md: "block" },
        }}
      >
        <MenuIcon sx={{ color: COLORS.primary }} />
      </IconButton>
    ) : (
      <img
        src={logo}
        alt="logo"
        style={{ width: isSm ? "10vh" : "20vh" }}
        onClick={() => navigate("/")}
      />
    );
  };

  const renderNonAuthenticatedMenu = () => {
    return (
      <Fragment>
        {isSm ? (
          <MenuIcon sx={{ color: COLORS.primary }} onClick={handleMenuOpen} />
        ) : (
          <Fragment>
            <Typography
              variant="font20b"
              className={classes.hover}
              onClick={() => {
                navigate(`/?div=home`);
              }}
              noWrap
            >
              Home
            </Typography>
            <Typography
              variant="font20b"
              className={classes.hover}
              onClick={(e) => {
                navigate("/login");
              }}
              noWrap
            >
              Login
            </Typography>
            <Typography
              variant="font20b"
              className={classes.hover}
              onClick={(e) => {
                navigate("/how-its-works");
              }}
              noWrap
            >
              How it works
            </Typography>
            {/* <Typography
              variant="font20b"
              className={classes.hover}
              onClick={(e) => {}}
              noWrap
            >
              Help
            </Typography> */}
            <Typography
              variant="font20b"
              className={classes.hover}
              onClick={() => {
                navigate(`/?div=contactUs`);
              }}
              noWrap
            >
              Contact Us
            </Typography>
          </Fragment>
        )}

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem
            onClick={(e) => {
              handleMenuClose();
              navigate("/");
            }}
          >
            Home
          </MenuItem>
          <MenuItem
            onClick={(e) => {
              handleMenuClose();
              navigate("/login");
            }}
          >
            Login
          </MenuItem>
          <MenuItem
            onClick={(e) => {
              handleMenuClose();
              navigate("/how-its-works");
            }}
          >
            How it works
          </MenuItem>
          {/* <MenuItem
            onClick={(e) => {
              handleMenuClose();
            }}
          >
            Help
          </MenuItem> */}
          <MenuItem
            onClick={(e) => {
              handleMenuClose();
            }}
          >
            Contact Us
          </MenuItem>
        </Menu>
      </Fragment>
    );
  };

  const renderAuthenticatedMenu = () => {
    return (
      <Fragment>
        {!isMenuIcon.state && (
          <Fragment>
            <Typography
              variant="font17"
              className={classes.hover}
              onClick={(e) => {
                navigate("/dashboard");
              }}
              noWrap
            >
              Dashboard
            </Typography>

            {(isAdmin || isSuperAdmin) && (
              <Fragment>
                <Typography
                  variant="font17"
                  className={classes.hover}
                  onClick={(e) => {
                    navigate("/authors");
                  }}
                  noWrap
                >
                  Authors
                </Typography>
                <Typography
                  variant="font17"
                  className={classes.hover}
                  onClick={(e) => {
                    navigate("/projects");
                  }}
                  noWrap
                >
                  Projects
                </Typography>{" "}
                <Typography
                  variant="font17"
                  className={classes.hover}
                  onClick={(e) => {
                    navigate("/audio-engineers");
                  }}
                  noWrap
                >
                  Audio engineers
                </Typography>
              </Fragment>
            )}
          </Fragment>
        )}
        <Badge badgeContent={unReadThreads.state} color="primary">
          <Tooltip title="Threads">
            <IconButton
              onClick={(e) => {
                navigate("/threads");
              }}
              size="small"
              color="inherit"
              aria-controls={
                notificationMenu.state ? "notification-menu" : undefined
              }
              aria-haspopup="true"
              aria-expanded={notificationMenu.state ? "true" : undefined}
            >
              <MailOutlineIcon style={{ color: COLORS.primary }} />
            </IconButton>
          </Tooltip>
        </Badge>
        <Badge
          variant="dot"
          invisible={!isNewNotifications.state}
          color="secondary"
        >
          <Tooltip title="Notifications">
            <IconButton
              onClick={(e) => {
                notificationMenu.update(e.currentTarget);
                fetchNotifications();
              }}
              size="small"
              color="inherit"
              aria-controls={
                notificationMenu.state ? "notification-menu" : undefined
              }
              aria-haspopup="true"
              aria-expanded={notificationMenu.state ? "true" : undefined}
            >
              <NotificationsNoneIcon style={{ color: COLORS.primary }} />
            </IconButton>
          </Tooltip>
        </Badge>
        <Tooltip title={getUserName()}>
          <IconButton
            onClick={(e) => profileMenu.update(e.currentTarget)}
            size="small"
            color="inherit"
            aria-controls={profileMenu.state ? "profile-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={profileMenu.state ? "true" : undefined}
          >
            <PermIdentityOutlined style={{ color: COLORS.primary }} />
          </IconButton>
        </Tooltip>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <AppBar
        position="sticky"
        style={{ backgroundColor: "#fff" }}
        open={isMenuIcon.state ? open.state : false}
      >
        {!isToolbarHidden.state && (
          <Toolbar>
            <Container maxWidth="0">
              <Grid
                container
                item
                xs={12}
                px={isAuthenticated ? 0 : { sm: 0, md: "8%" }}
              >
                <Grid container item xs={6} alignItems="center">
                  {renderIcon()}
                </Grid>
                <Grid
                  container
                  item
                  xs={6}
                  justifyContent="flex-end"
                  alignItems={isAuthenticated ? "center" : "flex-end"}
                  pb={isAuthenticated ? 0 : 3}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={{ xs: 0, sm: 1, md: 2 }}
                  >
                    {isAuthenticated
                      ? renderAuthenticatedMenu()
                      : renderNonAuthenticatedMenu()}
                  </Stack>
                </Grid>
              </Grid>
            </Container>
          </Toolbar>
        )}
        {!isMenuIcon.state && isAuthenticated && (
          <Box display="flex" justifyContent="center">
            {" "}
            <Tooltip title={isToolbarHidden.state ? "Show menu" : "Hide menu"}>
              <Box
                sx={{
                  position: "absolute",
                  backgroundColor: COLORS.white,
                  borderRadius: "0% 0% 50% 50%",
                }}
              >
                <IconButton
                  size="small"
                  onClick={(e) => {
                    isToolbarHidden.update(!isToolbarHidden.state);
                  }}
                >
                  {isToolbarHidden.state ? (
                    <ExpandMoreIcon sx={{ color: "black" }} />
                  ) : (
                    <ExpandLessIcon sx={{ color: "black" }} />
                  )}
                </IconButton>
              </Box>
            </Tooltip>
          </Box>
        )}
      </AppBar>{" "}
      <Menu
        anchorEl={profileMenu.state}
        id="profile-menu"
        open={Boolean(profileMenu.state)}
        onClose={() => profileMenu.update(null)}
        onClick={() => profileMenu.update(null)}
        PaperProps={menuProps.paperProps}
        transformOrigin={menuProps.transformOrigin}
        anchorOrigin={menuProps.anchorOrigin}
      >
        <MenuItem
          style={{ color: COLORS.primary }}
          onClick={() => {
            navigate("/profile");
          }}
        >
          <ListItemIcon style={{ color: COLORS.primary }}>
            <ManageAccountsIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="font15" noWrap>
            Profile
          </Typography>
        </MenuItem>
        <MenuItem style={{ color: COLORS.primary }} onClick={logOutUser}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" style={{ color: COLORS.primary }} />
          </ListItemIcon>
          <Typography variant="font15" noWrap>
            Logout
          </Typography>
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={notificationMenu.state}
        id="notification-menu"
        open={Boolean(notificationMenu.state)}
        onClose={() => notificationMenu.update(null)}
        onClick={() => notificationMenu.update(null)}
        PaperProps={{
          style: {
            ...menuProps.paperProps,
            minWidth: NOTIFICATION_MENU_WIDTH,
          },
        }}
        transformOrigin={menuProps.transformOrigin}
        anchorOrigin={menuProps.anchorOrigin}
      >
        <Box
          px={2}
          pb={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="font20b" noWrap color={COLORS.secondary}>
            Notifications
          </Typography>
          <Link
            href=""
            variant="font15"
            underline="none"
            color={COLORS.secondary}
            sx={{
              "&:hover": {
                color: COLORS.primary,
              },
            }}
            onClick={(e) => {
              e.preventDefault();
              navigate(`/notifications`);
            }}
          >
            See all
          </Link>
        </Box>
        <Divider />

        <PrimaryNotifications {...{ notifications, fetchNotifications }} />
      </Menu>
    </Fragment>
  );
}

export default AppToolbar;
