import React, { Fragment } from "react";
import { Button, Tooltip, Typography } from "@mui/material";
import { primaryButtonHover } from "components/common/Styles";
import { BOOK_STATUS_CODES as BookStatusCodes } from "constants";

function SelectRetailSampleButton({
  status,
  bookDetails,
  currentUrlToPlay,
  handlePlayButton,
  isWavePlaying,
  openSelectRetailSampleModal,
  fetchBookChaptersWithAudio,
}) {
  const isButtonVisible =
    status === BookStatusCodes.readyForProduction ||
    status === BookStatusCodes.completed;
  const isPlayingSameUrl =
    currentUrlToPlay.state === bookDetails.state?.retailSample;

  return (
    isButtonVisible && (
      <Fragment>
        {bookDetails.state?.retailSample === "processing" ? (
          <Tooltip title="Click to refresh">
            <Button
              variant="outlined"
              sx={primaryButtonHover}
              onClick={() => {
                fetchBookChaptersWithAudio();
              }}
            >
              <Typography variant="font15b">Audio processing</Typography>
            </Button>
          </Tooltip>
        ) : (
          <Button
            disabled={!bookDetails.state?.retailSample}
            variant="outlined"
            sx={primaryButtonHover}
            onClick={() =>
              isPlayingSameUrl
                ? handlePlayButton()
                : currentUrlToPlay.update(bookDetails.state?.retailSample)
            }
          >
            <Typography variant="font15b" noWrap>
              {isWavePlaying.state && isPlayingSameUrl ? "Pause" : "Play"}{" "}
              retail sample
            </Typography>
          </Button>
        )}
        {status === BookStatusCodes.readyForProduction && (
          <Button
            disabled={
              isWavePlaying.state ||
              bookDetails.state?.retailSample === "processing"
            }
            variant="outlined"
            sx={primaryButtonHover}
            onClick={() => {
              currentUrlToPlay.update(null);
              openSelectRetailSampleModal.update(true);
            }}
          >
            <Typography variant="font15b" noWrap>
              {bookDetails.state?.retailSample
                ? "Change retail sample"
                : "Select retail sample"}
            </Typography>
          </Button>
        )}
      </Fragment>
    )
  );
}

export default SelectRetailSampleButton;
