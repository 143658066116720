import React, { Fragment, useEffect } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  IconButton,
  Stack,
  Tooltip,
  TextField,
} from "@mui/material";
import AddParagraph from "assets/images/add-file.png";
import DeleteParagraph from "assets/images/file-delete.png";
import SaveParagraph from "assets/images/save-para.png";
import DeleteParagraphIcon from "assets/images/bin.png";
import SaveParagraphIcon from "assets/images/save.png";
import {
  verifyRightSideButtons,
  iconButtonImage,
  borderLessTextField,
  pageNumberIndicator,
} from "components/common/Styles";
import { COLORS } from "constants";
import { useStateUpdate } from "UseStateUpdate";
import MergeDownButton from "./MergeDownButton";
import MergeUpButton from "./MergeUpButton";
import AddParagraphButton from "./AddParagraphButton";
import NextPageParagraph from "./NextPageParagraph";
import Toast from "components/common/Toast";

function VerifyPageRightScrollable(props) {
  const {
    openConfirmModal,
    pageContents,
    isParagraphActionsEnabled,
    selectedIndex,
    callUpdateParagraph,
    callDeletePara,
    callMergePara,
    callCreatePara,
    pages,
    setConfirmModalData,
    onEditOrderIndex,
    pageDetails,
    fetchPageDetails,
    backDropOpen,
  } = props;
  const classes = verifyRightSideButtons();
  const classes2 = iconButtonImage();
  const classes3 = borderLessTextField();
  const classes4 = pageNumberIndicator();
  const pageContentsToEdit = useStateUpdate([]);
  const currentEditingParagraph = useStateUpdate("");
  const nextPageIdToLoad = useStateUpdate(0);
  const nextPageFirstParagraph = useStateUpdate(null);
  const severity = useStateUpdate("success");
  const toastMessage = useStateUpdate("");
  const toastOpen = useStateUpdate(false);

  useEffect(() => {
    pageContents.state.length > 0
      ? pageContentsToEdit.update(pageContents.state)
      : pageContentsToEdit.update([
          {
            orderIndex: 100,
            paragraphText: "",
            newPara: true,
          },
        ]);
    nextPageIdToLoad.state &&
      fetchPageDetails(nextPageIdToLoad.state, nextPageFirstParagraph);
  }, [pageContents.state]);

  useEffect(() => {
    resetNextPageStates();
  }, [selectedIndex.state]);

  const calltoast = async (severityIs, messageIs) => {
    severity.update(severityIs);
    toastMessage.update(messageIs);
    toastOpen.update(true);
  };

  const handleParagraphChange = (newParagraphText, orderIndex) => {
    let newPageContent = [];
    pageContentsToEdit.state.forEach((paragraph) => {
      let newParagraph = paragraph;
      if (paragraph.orderIndex === orderIndex) {
        newParagraph = {
          ...paragraph,
          paragraphText: newParagraphText,
        };
      }
      newPageContent.push(newParagraph);
    });
    currentEditingParagraph.update(newParagraphText);
    pageContentsToEdit.update(newPageContent);
  };

  const handleSaveCancel = () => {
    pageContentsToEdit.update(pageContents.state);
    onEditOrderIndex.update(null);
    openConfirmModal.update(false);
  };

  const addNewParagraph = (indexIs) => {
    let newPageContent = [];
    pageContentsToEdit.state.forEach((paragraph, index) => {
      let newParagraph = paragraph;
      newPageContent.push(newParagraph);
      if (indexIs === index) {
        let newIndex;
        if (pageContentsToEdit.state.length - 1 === index) {
          newIndex = Math.round(paragraph.orderIndex + 100);
        } else {
          newIndex = Math.round(
            (paragraph.orderIndex +
              pageContentsToEdit.state[index + 1].orderIndex) /
              2
          );
        }

        newParagraph = {
          orderIndex: newIndex,
          paragraphText: "",
          newPara: true,
        };
        newPageContent.push(newParagraph);
      }
    });
    pageContentsToEdit.update(newPageContent);
  };

  const getPrevPageLastPara = async (pageId) => {
    const result = await pageDetails(pageId);
    if (result.status === 200) {
      try {
        const { data } = result.data;
        return data[data.length - 1].paragraphId;
      } catch {
        return null;
      }
    }
  };

  const getNextPageFirstPara = async (pageId) => {
    const result = await pageDetails(pageId);
    if (result.status === 200) {
      try {
        const { data } = result.data;
        return data[0].paragraphId;
      } catch {
        return null;
      }
    }
  };

  const resetNextPageStates = () => {
    nextPageIdToLoad.update(0);
    nextPageFirstParagraph.update(null);
  };

  return (
    <Fragment>
      {pageContentsToEdit.state.map((content, index) => (
        <Paper
          key={content.orderIndex}
          variant="outlined"
          sx={{
            width: "100%",
            my: 5,
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
          }}
        >
          <Box p={1}>
            <Grid container>
              <Grid item xs={12} sx={{ display: "flex" }}>
                <Box className={classes4.verifyPageRight}>
                  <Typography variant="font16b" color={COLORS.white}>
                    {index + 1}
                  </Typography>
                </Box>
                <TextField
                  disabled={pages.state[selectedIndex.state].isVerified}
                  fullWidth
                  multiline
                  className={classes3.textField}
                  value={content.paragraphText}
                  onChange={(e) => {
                    onEditOrderIndex.update(content.orderIndex);
                    handleParagraphChange(e.target.value, content.orderIndex);
                  }}
                  onBlur={() => {
                    if (content.orderIndex === onEditOrderIndex.state) {
                      setConfirmModalData(
                        SaveParagraphIcon,
                        "Save paragraph",
                        "Are you sure you want to save the changes?",
                        "Yes, save paragraph",
                        "Cancel, discard changes",
                        () => {
                          content.newPara
                            ? callCreatePara({
                                orderIndex: content.orderIndex,
                                paragraphText: currentEditingParagraph.state,
                              })
                            : callUpdateParagraph(content.paragraphId, {
                                paragraphText: currentEditingParagraph.state,
                              });
                        },
                        () => {
                          handleSaveCancel();
                        }
                      );
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} container justifyContent="flex-end">
                {!pages.state[selectedIndex.state].isVerified && (
                  <Stack direction="row" spacing={1}>
                    {onEditOrderIndex.state === content.orderIndex ? (
                      <AddParagraphButton
                        {...{
                          content,
                          callCreatePara,
                          currentEditingParagraph,
                          callUpdateParagraph,
                          SaveParagraphIcon,
                          handleSaveCancel,
                          SaveParagraph,
                          setConfirmModalData,
                        }}
                      />
                    ) : (
                      isParagraphActionsEnabled.state &&
                      !content.newPara && (
                        <Fragment>
                          {!(selectedIndex.state === 2 && index === 0) && (
                            <MergeUpButton
                              {...{
                                index,
                                content,
                                callMergePara,
                                pageContentsToEdit,
                                selectedIndex,
                                pages,
                                getPrevPageLastPara,
                                setConfirmModalData,
                                calltoast,
                                openConfirmModal,
                                backDropOpen,
                              }}
                              lineBreak={1}
                            />
                          )}
                          {!(
                            index === pageContentsToEdit.state.length - 1 &&
                            selectedIndex.state === pages.state.length - 2
                          ) && (
                            <MergeDownButton
                              {...{
                                index,
                                content,
                                callMergePara,
                                pageContentsToEdit,
                                selectedIndex,
                                pages,
                                getNextPageFirstPara,
                                setConfirmModalData,
                                calltoast,
                                openConfirmModal,
                                backDropOpen,
                              }}
                              lineBreak={1}
                            />
                          )}
                          {!(selectedIndex.state === 2 && index === 0) && (
                            <MergeUpButton
                              {...{
                                index,
                                content,
                                callMergePara,
                                pageContentsToEdit,
                                selectedIndex,
                                pages,
                                getPrevPageLastPara,
                                setConfirmModalData,
                                calltoast,
                                openConfirmModal,
                                backDropOpen,
                              }}
                              lineBreak={0}
                            />
                          )}
                          {!(
                            index === pageContentsToEdit.state.length - 1 &&
                            selectedIndex.state === pages.state.length - 2
                          ) && (
                            <MergeDownButton
                              {...{
                                index,
                                content,
                                callMergePara,
                                pageContentsToEdit,
                                selectedIndex,
                                pages,
                                getNextPageFirstPara,
                                setConfirmModalData,
                                calltoast,
                                openConfirmModal,
                                backDropOpen,
                              }}
                              lineBreak={0}
                            />
                          )}
                          <Tooltip title="Add a paragraph" placement="top">
                            <IconButton
                              color="primary"
                              onClick={() => addNewParagraph(index)}
                              className={classes.button}
                            >
                              <img
                                src={AddParagraph}
                                className={classes2.buttonImage}
                                alt="Add"
                              />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete paragraph" placement="top">
                            <IconButton
                              color="primary"
                              onClick={() => {
                                setConfirmModalData(
                                  DeleteParagraphIcon,
                                  "Delete paragraph......",
                                  "Are you sure you want to delete this paragraph?",
                                  "Yes, delete paragraph",
                                  "Cancel, keep paragraph",
                                  () => {
                                    callDeletePara(content.paragraphId);
                                  },
                                  null
                                );
                              }}
                              className={classes.button}
                            >
                              <img
                                src={DeleteParagraph}
                                className={classes2.buttonImage}
                                alt="Delete"
                              />
                            </IconButton>
                          </Tooltip>
                        </Fragment>
                      )
                    )}
                  </Stack>
                )}
              </Grid>
            </Grid>
          </Box>
        </Paper>
      ))}

      {selectedIndex.state < pages.state.length - 2 &&
      selectedIndex.state > 1 &&
      pageContents.state.length ? (
        <NextPageParagraph
          {...{
            pages,
            selectedIndex,
            pageContents,
            fetchPageDetails,
            callMergePara,
            nextPageFirstParagraph,
            resetNextPageStates,
            nextPageIdToLoad,
            setConfirmModalData,
            calltoast,
          }}
        />
      ) : null}
      <Toast
        severity={severity.state}
        toastMessage={toastMessage.state}
        toastOpen={toastOpen}
      />
    </Fragment>
  );
}

export default VerifyPageRightScrollable;
