import React, { Fragment } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  Modal,
  Stack,
  CircularProgress,
  IconButton,
  Paper,
  TextField,
  Divider,
  Hidden,
} from "@mui/material/";
import CloseIcon from "@mui/icons-material/Close";
import { smallModalStyle } from "components/common/Styles";
import { isEmptyString } from "utils/FormValidator";
import { useStateUpdate } from "UseStateUpdate";
import { formatPhoneNumber } from "utils/FormatPhoneNumber";
import { isValidEmail } from "../../utils/FormValidator";
import { createUser } from "service/UserService";
import { UNEXPECTED_ERROR_MESSAGE } from "../../constants/otherConstant";
import { THREAD_TYPES, COLORS, USER_TYPES } from "constants";
import Toast from "../common/Toast";
import { reFormatPhoneNumber } from "../../utils/FormatPhoneNumber";
import { sendComment } from "service/ChatService";
import { capitalize } from "../../utils/WordsUtils";

const Footer = ({
  isLoader,
  userType,
  checkValidatorOnAddUser,
  callCreateUser,
}) => {
  return (
    <Box p={4}>
      <Grid container justifyContent="flex-end">
        <Button
          variant="contained"
          disabled={isLoader.state}
          size="small"
          onClick={(e) => {
            checkValidatorOnAddUser() && callCreateUser();
          }}
        >
          <Typography variant="font14b" color={COLORS.white} mx={4} noWrap>
            Create {userType}
          </Typography>
          {isLoader.state && (
            <CircularProgress
              size={24}
              sx={{
                position: "absolute",
                marginLeft: "-10px",
              }}
            />
          )}
        </Button>
      </Grid>
    </Box>
  );
};

function UserCreateModal({ open, userTypeIs, fetchUsers }) {
  const firstName = useStateUpdate("");
  const lastName = useStateUpdate("");
  const email = useStateUpdate("");
  const phoneNumber = useStateUpdate("");
  const isLoader = useStateUpdate(false);
  const userType = useStateUpdate(
    userTypeIs === "author"
      ? USER_TYPES.author
      : userTypeIs === "admin"
      ? USER_TYPES.admin
      : USER_TYPES.audioEngineer
  );
  const severity = useStateUpdate("success");
  const toastMessage = useStateUpdate("");
  const toastOpen = useStateUpdate(false);

  const validationError = useStateUpdate({
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
  });

  function checkValidatorOnAddUser() {
    const { state: firstNameValue } = firstName;
    const { state: lastNameValue } = lastName;
    const { state: emailValue } = email;
    const { state: phoneNumberValue } = phoneNumber;

    const newValidationError = {
      email: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
    };

    if (isEmptyString(firstNameValue)) {
      newValidationError.firstName = "First name is required";
    }
    if (isEmptyString(lastNameValue)) {
      newValidationError.lastName = "Last name is required";
    }
    if (isEmptyString(emailValue)) {
      newValidationError.email = "Email is required";
    } else if (!isValidEmail(emailValue)) {
      newValidationError.email = "Email is not valid.";
    }

    validationError.update(newValidationError);
    return Object.values(newValidationError).every((value) => value === "");
  }

  const callCreateUser = async () => {
    try {
      isLoader.update(true);
      let data = getUserDetails();
      let response = await createUser(data);

      if (response.status === 200) {
        calltoast("success", `${userType.state} has been created`);
        fetchUsers();
        setTimeout(handleClose, 2000);
        if (userType.state === USER_TYPES.author) {
          callCreateThread(response.data.data.userId);
        }
      } else if (response.status === 409) {
        calltoast("error", "Email Id already exists");
      } else {
        calltoast("error", response.data.message);
      }
    } catch (error) {
      console.error("Error in callCreateUser:", error);
      calltoast("error", UNEXPECTED_ERROR_MESSAGE);
    } finally {
      // Hide loader whether operation succeeds or fails
      isLoader.update(false);
    }
  };

  const callCreateThread = async (userId) => {
    let data = getThreadDetails(userId);
    await sendComment(data);
  };

  const getThreadDetails = (receiverUserId) => {
    return {
      title: `${capitalize(firstName.state)} ${capitalize(lastName.state)}`,
      description: ``,
      message: `Welcome, ${capitalize(firstName.state)} ${capitalize(
        lastName.state
      )}. You can use this thread for general inquiries.`,
      receiverUserId,
      threadType: THREAD_TYPES.general,
      pageUri: ``,
    };
  };

  const getUserDetails = () => ({
    userType: userType.state,
    firstName: firstName.state,
    lastName: lastName.state,
    email: email.state,
    // phone: reFormatPhoneNumber(phoneNumber.state),
    // phone: "",
  });

  const calltoast = (severityIs, messageIs) => {
    severity.update(severityIs);
    toastMessage.update(messageIs);
    toastOpen.update(true);
  };

  const handleClose = () => {
    firstName.update("");
    lastName.update("");
    email.update("");
    phoneNumber.update("");
    open.update(false);
  };

  return (
    <Fragment>
      <Modal open={open.state} onClose={handleClose}>
        <Box sx={smallModalStyle}>
          <Stack
            direction="row"
            mb={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="font20b" color={COLORS.primary}>
              Create {userTypeIs}
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Paper variant={"outlined"}>
            <Box p={4}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    disabled={isLoader.state}
                    error={Boolean(validationError.state.firstName)}
                    helperText={validationError.state.firstName}
                    id="first-name"
                    label={<Typography variant="font15">First name</Typography>}
                    variant="standard"
                    color="primary"
                    fullWidth
                    value={firstName.state}
                    inputProps={{ maxLength: 50 }}
                    onChange={(e) => {
                      validationError.update({
                        ...validationError.state,
                        firstName: "",
                      });
                      firstName.update(e.target.value);
                    }}
                    onBlur={(e) => {
                      if (isEmptyString(e.target.value)) {
                        validationError.update({
                          ...validationError.state,
                          firstName: "First name is required",
                        });
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    disabled={isLoader.state}
                    error={Boolean(validationError.state.lastName)}
                    helperText={validationError.state.lastName}
                    id="last-name"
                    label={<Typography variant="font15">Last name</Typography>}
                    variant="standard"
                    color="primary"
                    fullWidth
                    value={lastName.state}
                    inputProps={{ maxLength: 50 }}
                    onChange={(e) => {
                      validationError.update({
                        ...validationError.state,
                        lastName: "",
                      });
                      lastName.update(e.target.value);
                    }}
                    onBlur={(e) => {
                      if (isEmptyString(e.target.value)) {
                        validationError.update({
                          ...validationError.state,
                          lastName: "Last name is required",
                        });
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    disabled={isLoader.state}
                    error={Boolean(validationError.state.email)}
                    helperText={
                      validationError.state.email === ""
                        ? ""
                        : validationError.state.email
                    }
                    type="email"
                    id="email"
                    label={<Typography variant="font15">Email</Typography>}
                    variant="standard"
                    color="primary"
                    fullWidth
                    value={email.state}
                    inputProps={{ maxLength: 50 }}
                    onChange={(e) => {
                      validationError.update({
                        ...validationError.state,
                        email: "",
                      });
                      email.update(e.target.value);
                    }}
                    onBlur={(e) => {
                      if (isEmptyString(e.target.value)) {
                        validationError.update({
                          ...validationError.state,
                          email: "Email is required",
                        });
                      } else if (!isValidEmail(e.target.value)) {
                        validationError.update({
                          ...validationError.state,
                          email: "Email is not valid.",
                        });
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    disabled
                    error={Boolean(validationError.state.phoneNumber)}
                    helperText={validationError.state.phoneNumber}
                    id="phone-number"
                    label="Phone number"
                    variant="standard"
                    color="primary"
                    fullWidth
                    value={phoneNumber.state}
                    inputProps={{ maxLength: 16 }}
                    onChange={(e) => {
                      validationError.update({
                        ...validationError.state,
                        phoneNumber: "",
                      });
                      phoneNumber.update(formatPhoneNumber(e.target.value));
                    }}
                  />
                </Grid>
                <Hidden mdDown>
                  <Grid item xs={6}></Grid>
                </Hidden>
              </Grid>
            </Box>
            <Divider />
            <Footer
              isLoader={isLoader}
              userType={userTypeIs}
              checkValidatorOnAddUser={checkValidatorOnAddUser}
              callCreateUser={callCreateUser}
            />
          </Paper>
        </Box>
      </Modal>
      <Toast
        severity={severity.state}
        toastMessage={toastMessage.state}
        toastOpen={toastOpen}
      />
    </Fragment>
  );
}

export default UserCreateModal;
