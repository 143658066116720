import React from "react";

import { Grid, TableRow, TableCell, Stack, Pagination } from "@mui/material";
import ShowItemsPerPage from "./ShowItemsPerPage";

function PaginationRow({ colSpanValue, perPage, page, pageCount }) {
  return (
    <TableRow>
      <TableCell colSpan={colSpanValue}>
        <Grid
          container
          direction="row"
          justifyContent={{ xs: "flex-start", sm: "flex-end" }}
          alignItems="center"
        >
          <Stack direction="row" spacing={2}>
            <ShowItemsPerPage perPage={perPage} items={[10, 20, 50, 100]} />
            <Pagination
              count={pageCount.state}
              showFirstButton
              showLastButton
              size="small"
              onChange={(_, value) => {
                page.update(value);
              }}
            />
          </Stack>
        </Grid>
      </TableCell>
    </TableRow>
  );
}

export default PaginationRow;
