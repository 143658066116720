import React from "react";
import { Grid, IconButton } from "@mui/material";

import FontDecrease from "assets/images/font-dec.png";
import FontIncrease from "assets/images/font-inc.png";
import NormalFont from "assets/images/font.png";
import ZoomIn from "assets/images/zoom-in.png";
import ZoomOut from "assets/images/zoom-out.png";
import Zoom from "assets/images/zoom.png";
import {
  zoomInAndOutIcon,
  zoomInAndOutIconImage,
} from "components/common/Styles";
import {
  PAGE_STEP_SIZE,
  TEXT_STEP_SIZE,
  PAGE_VIEW_NORMAL_SIZE,
  TEXT_TO_RECORD_NORMAL_FONT_SIZE,
} from "constants/otherConstant";

function ZoomInAndOut(props) {
  const { tabValue, textToRecordFontSize, pageViewSize } = props;

  return (
    <Grid
      spacing={5}
      container
      justifyContent="center"
      alignItems="center"
      mt={10}
    >
      <Grid item xs={12}>
        <IconButton
          sx={zoomInAndOutIcon}
          size="large"
          onClick={(e) => {
            if (tabValue.state) {
              pageViewSize.state < 200 &&
                pageViewSize.update(pageViewSize.state + PAGE_STEP_SIZE);
            } else {
              textToRecordFontSize.state < 31 &&
                textToRecordFontSize.update(
                  textToRecordFontSize.state + TEXT_STEP_SIZE
                );
            }
          }}
        >
          <img
            src={tabValue.state ? ZoomIn : FontIncrease}
            style={zoomInAndOutIconImage}
            alt="Font Increase"
          />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <IconButton
          sx={zoomInAndOutIcon}
          size="large"
          onClick={(e) => {
            if (tabValue.state) {
              pageViewSize.update(PAGE_VIEW_NORMAL_SIZE);
            } else {
              textToRecordFontSize.update(TEXT_TO_RECORD_NORMAL_FONT_SIZE);
            }
          }}
        >
          <img
            src={tabValue.state ? Zoom : NormalFont}
            style={zoomInAndOutIconImage}
            alt="Normal Font"
          />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <IconButton
          sx={zoomInAndOutIcon}
          size="large"
          onClick={(e) => {
            if (tabValue.state) {
              pageViewSize.state > 100 &&
                pageViewSize.update(pageViewSize.state - PAGE_STEP_SIZE);
            } else {
              textToRecordFontSize.state > 19 &&
                textToRecordFontSize.update(
                  textToRecordFontSize.state - TEXT_STEP_SIZE
                );
            }
          }}
        >
          <img
            src={tabValue.state ? ZoomOut : FontDecrease}
            style={zoomInAndOutIconImage}
            alt="Font Decrease"
          />
        </IconButton>
      </Grid>
    </Grid>
  );
}

export default ZoomInAndOut;
