import React from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import { COLORS } from "constants";
import { ThreeDots } from "react-loader-spinner";
import PlayBtn from "assets/images/playWave.png";
import PauseBtn from "assets/images/pauseWave.png";

function PageImage({
  isWaveLoading,
  isWavePlaying,
  currentWaveTime,
  handlePlayButton,
}) {
  return isWaveLoading.state ? (
    <ThreeDots height="20" color={COLORS.primary} />
  ) : (
    <Stack>
      <IconButton
        disabled={isWaveLoading.state}
        color="primary"
        onClick={handlePlayButton}
      >
        <img
          src={isWavePlaying.state ? PauseBtn : PlayBtn}
          style={{ width: "70px", height: "70px" }}
          alt="play"
        />
      </IconButton>
      <Typography variant="font15b" align="center" color={COLORS.primary}>
        {currentWaveTime.state}
      </Typography>
    </Stack>
  );
}

export default PageImage;
