import React, { Fragment, useEffect } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Stack,
  IconButton,
  Tooltip,
} from "@mui/material";
import {
  VerifyPageButton,
  verifyLeftSideButtons,
  pageNumberIndicator,
} from "components/common/Styles";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ZoomInOutlinedIcon from "@mui/icons-material/ZoomInOutlined";
import DeletePageIcon from "assets/images/added-file-delete.png";
import { EXTRA_PAGES_INDEX as ExtraPagesIndex } from "constants/otherConstant";
import { COLORS } from "constants";
import { getUrl } from "utils/GetUrl";
import ImageComponent from "components/common/ImageComponent";
import { getImageUrl } from "utils/GetUrl";

function VerifyPageLeftScrollable(props) {
  const {
    openPageViewModal,
    callUpdatePageStatus,
    callDeletePage,
    pages,
    pageUrl,
    selectedIndex,
    setConfirmModalData,
    bookId,
    pageDetails,
    isFilterOnlyUnVerified,
  } = props;
  const classes2 = pageNumberIndicator();

  const isCreditPage = (index) => {
    return !(
      index === ExtraPagesIndex.sampleRecord ||
      index === ExtraPagesIndex.openingCredits ||
      index === pages.state.length - 1
    );
  };

  useEffect(() => {
    scrollToDiv(selectedIndex.state);
  }, [selectedIndex.state]);

  const scrollToDiv = (id) => {
    const targetDiv = document.getElementById(id);
    if (targetDiv) {
      targetDiv.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const checkPageParagraphAvailable = async (pageId) => {
    const result = await pageDetails(pageId);
    if (result.status === 200) {
      const { data } = result.data;
      return data.length;
    }
  };

  const handlePageStatusChange = async (isChecked, page, index) => {
    if (await checkPageParagraphAvailable(page.pageId)) {
      callUpdatePageStatus(page.pageId, {
        isVerified: isChecked,
      });
      isChecked &&
        selectedIndex.state < pages.state.length - 1 &&
        selectedIndex.update(selectedIndex.state + 1);
    } else {
      setConfirmModalData(
        DeletePageIcon,
        "No paragraphs",
        "Since there is no paragraph associated with this page, please delete it.",
        "Yes, delete empty page",
        "Cancel",
        () => {
          callDeletePage(page.pageId, index);
        },
        null
      );
    }
  };

  return (
    <Fragment>
      {pages.state.map(
        (page, index) =>
          index !== 0 &&
          (isFilterOnlyUnVerified.state ? !page.isVerified : true) && (
            <Paper
              id={index}
              variant="outlined"
              sx={{
                borderRadius: "15px",
                my: 10,
                border:
                  pages.state[selectedIndex.state].pageId === page.pageId
                    ? "2px solid #273891"
                    : "1px solid #ccc",
                boxShadow:
                  pages.state[selectedIndex.state].pageId === page.pageId
                    ? "0px 0px 10px rgba(39, 56, 145, 1)"
                    : "0px 0px 10px rgba(0, 0, 0, 0.3)",
              }}
              key={page.pageId}
              onClick={() => {
                selectedIndex.update(index);
              }}
            >
              <Grid container>
                <Grid item xs={6}>
                  <Box className={classes2.verifyPageLeft}>
                    <Typography variant="font17b" color={COLORS.white}>
                      {index}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} container justifyContent="flex-end">
                  <Tooltip title="Confirm page" placement="top">
                    <VerifyPageButton
                      checked={page.isVerified}
                      onChange={(e) => {
                        handlePageStatusChange(e.target.checked, page, index);
                      }}
                      onClick={(event) => {
                        selectedIndex.update(index);
                        event.stopPropagation();
                      }}
                    />
                  </Tooltip>
                </Grid>
                <Grid item xs={12}>
                  <ImageComponent
                    src={getImageUrl(page.pageImageUrl)}
                    style={{ width: "100%" }}
                    alt="page-image"
                  />
                </Grid>
                <Grid item xs={12} container justifyContent="flex-end">
                  <Stack direction="row" spacing={2} m={0.5}>
                    <Tooltip title="Preview page" placement="top">
                      <IconButton
                        onClick={() => {
                          pageUrl.update(getImageUrl(page.pageImageUrl));
                          openPageViewModal.update(true);
                        }}
                        size="small"
                        onMouseDown={(e) => e.preventDefault()}
                        sx={verifyLeftSideButtons}
                      >
                        <ZoomInOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                    {isCreditPage(index) && (
                      <Tooltip title="Delete page" placement="top">
                        <IconButton
                          onClick={() => {
                            setConfirmModalData(
                              DeletePageIcon,
                              "Delete page",
                              "Are you sure you want to delete this page?",
                              "Yes, delete page",
                              "Cancel, keep the page",
                              () => {
                                callDeletePage(page.pageId, index);
                              },
                              null
                            );
                          }}
                          onMouseDown={(e) => e.preventDefault()}
                          sx={verifyLeftSideButtons}
                        >
                          <DeleteOutlineOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Paper>
          )
      )}
    </Fragment>
  );
}

export default VerifyPageLeftScrollable;
