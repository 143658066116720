import "./App.css";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { basetheme } from "components/common/Theme";
import LogIn from "components/login/LogIn";
import { useStateUpdate } from "UseStateUpdate";
import AppToolBar from "components/toolbar/AppToolBar";
import MainDrawer from "components/main_drawer/MainDrawer";
import UnProtectedRoute from "components/common/UnProtectedRoute";
import ProtectedRoute from "components/common/ProtectedRoute";
import ForgotPassword from "components/forgot_reset_password/ForgotPassword";
import ManageAuthors from "components/ManageAuthors/ManageAuthors";
import ManageAdmins from "components/ManageAdmins/ManageAdmins";
import ManageProjects from "components/ManageProjects/ManageProjects";
import ManageAudioEngineers from "components/ManageAudioEngineers/ManageAudioEngineers";
import ProjectDetails from "components/projectDetails/ProjectDetails";
import ResetPassword from "components/forgot_reset_password/ResetPassword";
import Dashboard from "components/dashboard/DashBoard";
import VerifyBook from "components/VerifyBook/VerifyBook";
import Voices from "components/Voices/Voices";
import BookGrouping from "components/BookGrouping/BookGrouping";
import Books from "components/Books/Books";
import Profile from "components/profile/Profile";
import HomePage from "components/homepage/HomePage";
import OptimizeAudio from "components/OptimizeAudio/OptimizeAudio";
import UserProfile from "components/profile/UserProfile";
import ReadyForProduction from "components/ReadyForProduction/ReadyForProduction";
import OptimizedAudio from "components/OptimizedAudio/OptimizedAudio";
import HowItWorks from "components/HowItWorks/HowItWorks";
import CompletedBooks from "components/CompletedBooks/CompletedBooks";
import TipsForRecording from "components/TipsForRecording/TipsForRecording";
import SampleRecording from "components/SampleRecording/SampleRecording";
import EmailTemplates from "components/EmailTemplate/EmailTemplates";
import RecordingStatus from "components/RecordingStatus/RecordingStatus";
import Notifications from "components/Notifications/Notifications";
import Threads from "components/Threads/Threads";

function App() {
  const dashboardOpen = useStateUpdate(window.innerWidth > 900);
  const isHideSideMenu = useStateUpdate(false);
  const isToolbarHidden = useStateUpdate(false);

  return (
    <ThemeProvider theme={basetheme}>
      <CssBaseline />
      <AppToolBar open={dashboardOpen} {...{ isToolbarHidden }} />
      <Routes>
        <Route
          path="/"
          element={
            <UnProtectedRoute>
              <HomePage />
            </UnProtectedRoute>
          }
        />
        <Route path="/login" element={<LogIn />} />
        <Route
          path="/forgot-password"
          element={
            <UnProtectedRoute>
              <ForgotPassword />
            </UnProtectedRoute>
          }
        />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route
          path="/account/new-account-verification"
          element={<ResetPassword />}
        />
        <Route
          path="/how-its-works"
          element={
            <UnProtectedRoute>
              <HowItWorks />
            </UnProtectedRoute>
          }
        />{" "}
        <Route
          path="/samplerecording"
          element={
            <UnProtectedRoute>
              <SampleRecording />
            </UnProtectedRoute>
          }
        />
        <Route
          path="/tips-for-recording-an-audiobook"
          element={
            <UnProtectedRoute>
              <TipsForRecording />
            </UnProtectedRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              {isHideSideMenu.state ? (
                <Dashboard {...{ isHideSideMenu }} />
              ) : (
                <MainDrawer open={dashboardOpen}>
                  <Dashboard {...{ isHideSideMenu }} />
                </MainDrawer>
              )}
            </ProtectedRoute>
          }
        />
        <Route
          path="/admins"
          element={
            <ProtectedRoute>
              <MainDrawer open={dashboardOpen}>
                <ManageAdmins />
              </MainDrawer>
            </ProtectedRoute>
          }
        />
        <Route
          path="/authors"
          element={
            <ProtectedRoute>
              <MainDrawer open={dashboardOpen}>
                <ManageAuthors />
              </MainDrawer>
            </ProtectedRoute>
          }
        />
        <Route
          path="/projects"
          element={
            <ProtectedRoute>
              <MainDrawer open={dashboardOpen}>
                <ManageProjects />
              </MainDrawer>
            </ProtectedRoute>
          }
        />
        <Route
          path="/audio-engineers"
          element={
            <ProtectedRoute>
              <MainDrawer open={dashboardOpen}>
                <ManageAudioEngineers />
              </MainDrawer>
            </ProtectedRoute>
          }
        />
        <Route
          path="/projects/:projectId"
          element={
            <ProtectedRoute>
              <MainDrawer open={dashboardOpen}>
                <ProjectDetails />
              </MainDrawer>
            </ProtectedRoute>
          }
        />
        <Route
          path="/voices/:bookId"
          element={
            <ProtectedRoute>
              <Voices {...{ isToolbarHidden }} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/verifybook/:bookId"
          element={
            <ProtectedRoute>
              <VerifyBook {...{ isToolbarHidden }} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/book-grouping/:bookId"
          element={
            <ProtectedRoute>
              <BookGrouping {...{ isToolbarHidden }} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/optimize-audio/:bookId"
          element={
            <ProtectedRoute>
              <OptimizeAudio />
            </ProtectedRoute>
          }
        />
        <Route
          path="/optimized-audio/:bookId"
          element={
            <ProtectedRoute>
              <OptimizedAudio />
            </ProtectedRoute>
          }
        />
        <Route
          path="/ready-for-production/:bookId"
          element={
            <ProtectedRoute>
              <ReadyForProduction />
            </ProtectedRoute>
          }
        />
        <Route
          path="/completed-book/:bookId"
          element={
            <ProtectedRoute>
              <CompletedBooks />
            </ProtectedRoute>
          }
        />
        <Route
          path="/books"
          element={
            <ProtectedRoute>
              <MainDrawer open={dashboardOpen}>
                <Books />
              </MainDrawer>
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              {isHideSideMenu.state ? (
                <Profile {...{ isHideSideMenu }} />
              ) : (
                <MainDrawer open={dashboardOpen}>
                  <Profile {...{ isHideSideMenu }} />
                </MainDrawer>
              )}
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile/:userId"
          element={
            <ProtectedRoute>
              <MainDrawer open={dashboardOpen}>
                <UserProfile />
              </MainDrawer>
            </ProtectedRoute>
          }
        />
        <Route
          path="/email-templates/"
          element={
            <ProtectedRoute>
              <EmailTemplates />
            </ProtectedRoute>
          }
        />
        <Route
          path="/recording-status/:bookId"
          element={
            <ProtectedRoute>
              <MainDrawer open={dashboardOpen}>
                <RecordingStatus />
              </MainDrawer>
            </ProtectedRoute>
          }
        />
        <Route
          path="/notifications"
          element={
            <ProtectedRoute>
              {isHideSideMenu.state ? (
                <Notifications {...{ isHideSideMenu }} />
              ) : (
                <MainDrawer open={dashboardOpen}>
                  <Notifications {...{ isHideSideMenu }} />
                </MainDrawer>
              )}
            </ProtectedRoute>
          }
        />
        <Route
          path="/threads"
          element={
            <ProtectedRoute>
              {isHideSideMenu.state ? (
                <Threads {...{ isHideSideMenu }} />
              ) : (
                <MainDrawer open={dashboardOpen}>
                  <Threads {...{ isHideSideMenu }} />
                </MainDrawer>
              )}
            </ProtectedRoute>
          }
        />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
