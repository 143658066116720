import React, { useEffect } from "react";
import { useStateUpdate } from "UseStateUpdate";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { BookUploadModal, WarningModal } from "components/modals";
import BooksTable from "./sections/BooksTable";
import { listBooks, updateBookStatus } from "service/BookService";
import { AssignAuthorModal } from "components/modals";
import { COLORS, BOOK_STATUS_CODES as BookStatusCodes } from "constants";

function AuthorDashboard() {
  let navigate = useNavigate();
  const search = useLocation().search;
  const bookStatus = new URLSearchParams(search).get("status");
  const booksList = useStateUpdate([]);
  const openUploadBookModal = useStateUpdate(false);
  const openWarningModal = useStateUpdate(false);
  const warningModalMessage = useStateUpdate("");
  const navigateTo = useStateUpdate("/dashboard");
  const rowsPerPage = useStateUpdate(10);
  const page = useStateUpdate(1);
  const pageCount = useStateUpdate(0);
  const order = useStateUpdate("desc");
  const orderBy = useStateUpdate("updatedAt");
  const filterValue = useStateUpdate("");
  const isFetching = useStateUpdate(true);
  const openAssignAuthorModal = useStateUpdate(false);
  const currentBook = useStateUpdate(null);

  useEffect(() => {
    bookStatus ? callFetchBooks() : navigate("/dashboard");
  }, [bookStatus, rowsPerPage.state, page.state, orderBy.state, order.state]);

  useEffect(() => {
    page.update(page.state > pageCount.state ? 1 : page.state);
  }, [pageCount.state]);

  useEffect(() => {
    filterValue.state === "" && callFetchBooks();
  }, [filterValue.state]);

  const callFetchBooks = () => {
    fetchBooks();
  };

  const fetchBooks = async () => {
    let listParams = `books/status/${bookStatus}?page=${page.state}&limit=${
      rowsPerPage.state
    }&sortBy=${orderBy.state}&sort=${order.state.toUpperCase()}&filter=${
      filterValue.state
    }`;

    const result = await listBooks(listParams);
    if (result.status === 200) {
      const { data } = result.data;
      booksList.update(data.books);
      pageCount.update(data.pagination.totalPages);
      currentBook.update(null);
      isFetching.update(false);
      if (data.books.length === 0) {
        openWarningModal.update(data.length === 0);
        warningModalMessage.update("No book was found.");
      }
    }
  };

  const getButtonLabel = (status) => {
    const statusOptions = {
      [BookStatusCodes.bookCreated]: "Upload book",
      [BookStatusCodes.bookUploading]: "Uploading book",
      [BookStatusCodes.bookProcessing]: "Book processing",
      [BookStatusCodes.bookExtFailed]: "Failed extraction",
      [BookStatusCodes.bookUploaded]: "Verify book",
      [BookStatusCodes.bookVerified]: "Group chapters",
      [BookStatusCodes.chapterGrouped]: "Assign authors",
      [BookStatusCodes.bookRecording]: "View recording status",
      [BookStatusCodes.bookRecorded]: "Optimize audio",
      [BookStatusCodes.audioMerging]: "Merging audio",
      [BookStatusCodes.audioMergeFailed]: "Merging failed",
      [BookStatusCodes.audioOptimized]: "Verify optimized audio",
      [BookStatusCodes.readyForProduction]: "View book",
      [BookStatusCodes.completed]: "View book",
    };

    return statusOptions[status] || "";
  };

  const getBookStatus = () => {
    const statusOptions = {
      [BookStatusCodes.bookCreated]: "Created books",
      [BookStatusCodes.bookProcessing]: "Uploaded book in processing",
      [BookStatusCodes.bookUploaded]: "Uploaded books",
      [BookStatusCodes.bookVerified]: "Verified books",
      [BookStatusCodes.chapterGrouped]: "Chapter grouped books",
      [BookStatusCodes.bookRecording]: "Books are in recording",
      [BookStatusCodes.audioMerging]: "Book audios in  merging",
      [BookStatusCodes.bookRecorded]: "Recorded books",
      [BookStatusCodes.audioOptimized]: "Audio optimized books",
      [BookStatusCodes.readyForProduction]: "Books are in ready for production",
      [BookStatusCodes.completed]: "Completed books",
    };

    return statusOptions[bookStatus] || "";
  };

  const checkBookStatus = (book) => {
    const bookId = book.bookId;
    const routes = {
      [BookStatusCodes.bookCreated]: (bookId) => {
        currentBook.update(book);
        openUploadBookModal.update(true);
      },
      [BookStatusCodes.bookUploaded]: (bookId) =>
        navigate(`/verifybook/${bookId}`),
      [BookStatusCodes.bookVerified]: (bookId) =>
        navigate(`/book-grouping/${bookId}`),
      [BookStatusCodes.chapterGrouped]: (bookId) => {
        openAssignAuthorModal.update(true);
        currentBook.update(book);
      },
      [BookStatusCodes.bookRecording]: (bookId) =>
        navigate(`/recording-status/${bookId}`),
      [BookStatusCodes.bookRecorded]: (bookId) =>
        navigate(`/optimize-audio/${bookId}`),
      [BookStatusCodes.audioOptimized]: (bookId) =>
        navigate(`/optimized-audio/${bookId}`),
      [BookStatusCodes.readyForProduction]: (bookId) =>
        navigate(`/ready-for-production/${bookId}`),
      [BookStatusCodes.completed]: (bookId) =>
        navigate(`/completed-book/${bookId}`),
    };

    const route = routes[bookStatus];
    if (route) {
      route(bookId);
    }
  };

  const callUpdateBookStatus = async () => {
    const body = { statusCode: BookStatusCodes.bookRecording };
    const result = await updateBookStatus(currentBook.state.bookId, body);
    result.status === 200 && fetchBooks();
  };

  const handleReassignAuthor = async (book) => {
    openAssignAuthorModal.update(true);
    currentBook.update(book);
  };

  return (
    <Box ml={{ xs: 2, md: 3 }} mr={{ xs: 2, md: 5 }}>
      {currentBook.state && (
        <BookUploadModal
          open={openUploadBookModal}
          bookId={currentBook.state.bookId}
          fetchDetails={fetchBooks}
        />
      )}
      <WarningModal
        {...{ openWarningModal, warningModalMessage, navigateTo }}
      />
      <AssignAuthorModal
        {...{ openAssignAuthorModal, currentBook, callUpdateBookStatus }}
      />
      <Grid mb={2}>
        <Typography variant="font20b" color={COLORS.primary}>
          {getBookStatus()}
        </Typography>
      </Grid>
      <Stack spacing={2}>
        <BooksTable
          isMiscellaneous={bookStatus === "MISCELLANEOUS"}
          isProcessing={
            bookStatus === BookStatusCodes.bookProcessing ||
            bookStatus === BookStatusCodes.audioMerging
          }
          {...{
            callFetchBooks,
            isFetching,
            filterValue,
            rowsPerPage,
            page,
            pageCount,
            order,
            orderBy,
            booksList,
            getButtonLabel,
            checkBookStatus,
            handleReassignAuthor,
          }}
        />
      </Stack>
    </Box>
  );
}

export default AuthorDashboard;
