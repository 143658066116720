import React, { useEffect } from "react";
import { Alert, Box, Grid, Typography } from "@mui/material";
import { useStateUpdate } from "UseStateUpdate";
import BookCard from "./sections/BookCard";
import { COLORS } from "constants";
import { getAuthorDashboardData } from "service/DashboardService";
import { useIsAuthorOrEngineer } from "utils/GetUserDetail";
import { ThreeDots } from "react-loader-spinner";

function AuthorDashboard() {
  const books = useStateUpdate(null);
  const isAuthorOrEngineer = useIsAuthorOrEngineer();

  useEffect(() => {
    fetchAuthorDashBoardData();
  }, []);

  const fetchAuthorDashBoardData = async () => {
    const result = await getAuthorDashboardData();
    if (result.status === 200) {
      const { data } = result.data;
      books.update(data[0].Books);
    }
  };

  const renderBooks = () => {
    return books.state.length === 0 ? (
      <Grid item xs={12} container justifyContent="center">
        <Alert severity="info">
          {/* Currently, no books are available. They may not have been assigned yet
          or are currently being processed. Please check again later. */}
          <Typography variant="font15">
            Your audiobook files are being processed. Once completed, your
            audiobook files will be forwarded to your publisher. <br />
            For questions related to your audiobook's availability on retail
            sites, contact your publisher.
          </Typography>
        </Alert>
      </Grid>
    ) : (
      books.state.map((book) => (
        <BookCard
          key={book.bookId}
          title={book.bookName}
          description={`Author: ${book.authorName}`}
          bookStatus={book.status.statusCode}
          {...{ book }}
        />
      ))
    );
  };

  const renderLoading = () => {
    return (
      <Grid item xs={12} container justifyContent="center">
        <ThreeDots height="30" color={COLORS.primary} />
      </Grid>
    );
  };

  return (
    <Box
      mx={{ xs: 2, md: isAuthorOrEngineer ? 10 : 5 }}
      my={isAuthorOrEngineer && 5}
    >
      <Grid mb={2}>
        <Typography variant="font20b" color={COLORS.primary}>
          Books
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        {books.state ? renderBooks() : renderLoading()}
      </Grid>
    </Box>
  );
}

export default AuthorDashboard;
