import React, { useEffect } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  Stack,
  CircularProgress,
  IconButton,
  Paper,
  Divider,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  InputBase,
  TableContainer,
  Table,
  TableBody,
} from "@mui/material/";
import { visuallyHidden } from "@mui/utils";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { rowHoverClickStyle } from "components/common/Styles";
import CloseIcon from "@mui/icons-material/Close";
import NoDataFound from "components/common/NoDataFound";
import PaginationRow from "components/common/PaginationRow";
import { formatPhoneNumber } from "utils/FormatPhoneNumber";
import { COLORS } from "constants";
import { capitalize } from "utils/WordsUtils";

const headCells = [
  { id: "firstName", label: "Author name" },
  { id: "email", label: "Email" },
  { id: "phone", label: "Phone number" },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography variant="font12b" color={COLORS.primary} noWrap>
                {headCell.label}
              </Typography>

              {orderBy === headCell.id ? (
                <Box sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function AuthorsTable(props) {
  const {
    openModal,
    page,
    pageCount,
    filterValue,
    rowsPerPage,
    orderBy,
    order,
    isFetching,
    authorsList,
    callFetchAuthors,
  } = props;
  let navigate = useNavigate();
  const classes = rowHoverClickStyle();

  useEffect(() => {
    callFetchAuthors();
  }, [rowsPerPage.state, page.state, orderBy.state, order.state]);

  useEffect(() => {
    page.update(page.state > pageCount.state ? 1 : page.state);
  }, [pageCount.state]);

  useEffect(() => {
    filterValue.state === "" && authorsList.state && callFetchAuthors();
  }, [filterValue.state]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy.state === property && order.state === "asc";
    order.update(isAsc ? "desc" : "asc");
    orderBy.update(property);
  };

  const authorDataRows = authorsList.state?.map((author) => (
    <TableRow
      key={author.userId}
      hover
      classes={{ hover: classes.hover }}
      onClick={() => {
        navigate(`/profile/${author.userId}`);
      }}
    >
      <TableCell>
        <Typography variant="font12">
          {`${capitalize(author.firstName)} ${capitalize(author.lastName)}`}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="font12">{author.email}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="font12">
          {formatPhoneNumber(author.phone)}
        </Typography>
      </TableCell>
    </TableRow>
  ));

  let tableContent;

  if (isFetching.state) {
    tableContent = (
      <TableRow>
        <TableCell colSpan={5}>
          <Grid container justifyContent="center" alignItems="center">
            <CircularProgress />
          </Grid>
        </TableCell>
      </TableRow>
    );
  } else if (authorsList.state.length === 0) {
    tableContent = <NoDataFound colSpanValue="3" />;
  } else {
    tableContent = authorDataRows;
  }

  return (
    <Grid container item xs={12}>
      <Paper variant="outlined" sx={{ width: "100%" }}>
        <Grid container p={2}>
          <Grid
            item
            xs={12}
            container
            justifyContent="flex-end"
            alignItems="center"
          >
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={{ xs: 2, sm: 4 }}
              alignItems="center"
            >
              <Paper
                component="form"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: { xs: 250, sm: 350 },
                }}
              >
                <InputBase
                  sx={{ ml: 1, width: { xs: 200, sm: 300 } }}
                  placeholder="Search by author name "
                  value={filterValue.state}
                  onChange={(e) => {
                    filterValue.update(e.target.value);
                  }}
                  onKeyPress={(event) => {
                    if (
                      event.code === "Enter" ||
                      event.code === "NumpadEnter"
                    ) {
                      event.preventDefault();
                      if (event.target.value !== "") {
                        callFetchAuthors();
                      }
                    }
                  }}
                />
                {filterValue.state !== "" && (
                  <IconButton
                    onClick={(e) => {
                      filterValue.update("");
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
                <IconButton
                  sx={{
                    backgroundColor: "primary.light",
                    borderRadius: "0%",
                  }}
                  onClick={(e) => {
                    if (filterValue.state !== "") {
                      callFetchAuthors();
                    }
                  }}
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
              <Button
                variant="outlined"
                size="small"
                onClick={(e) => {
                  openModal.update(true);
                }}
              >
                <Typography variant="buttonLabel" noWrap>
                  Create author
                </Typography>
              </Button>
            </Stack>
          </Grid>
        </Grid>

        <Divider />

        <Box sx={{ width: { xs: "90vw", sm: "100%" } }}>
          <TableContainer component={Paper}>
            <Table size="small">
              <EnhancedTableHead
                order={order.state}
                orderBy={orderBy.state}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {tableContent}
                {authorsList.state?.length > 0 && (
                  <PaginationRow
                    perPage={rowsPerPage}
                    page={page}
                    pageCount={pageCount}
                    colSpanValue={3}
                  />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Divider />
      </Paper>
    </Grid>
  );
}

export default AuthorsTable;
