import { Skeleton } from "@mui/material";
import React, { useState, useEffect } from "react";

const ImageComponent = ({ src, style, alt }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = src;

    const timeout = setTimeout(() => {
      setLoading(false);
    }, 5000);

    img.onload = () => {
      setLoading(false);
      clearTimeout(timeout);
    };

    img.onerror = () => {
      setLoading(false);
      setError(true);
    };

    return () => {
      clearTimeout(timeout);
    };
  }, [src]);

  if (loading) {
    return <Skeleton variant="rectangular" width={"100%"} height={120} />;
  }

  if (error) {
    return <p>Error loading image!</p>;
  }

  return <img src={src} style={style} alt={alt} />;
};

export default ImageComponent;
