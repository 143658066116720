import React from "react";
import {
  Box,
  Grid,
  IconButton,
  Typography,
  Stack,
  Button,
  Tooltip,
} from "@mui/material";
import Info from "assets/images/info.png";
import { recordPageFooter, primaryButtonHover } from "components/common/Styles";
import FooterPageNumber from "components/common/FooterPageNumber";
import MergeParagraphIcon from "assets/images/text.png";

function VerifyPageFooter(props) {
  const {
    selectedIndex,
    totalPages,
    callMergeAllPara,
    mergeAllParaDisabled,
    setConfirmModalData,
    isVerifyCompleted,
    callGroupChapters,
    openGuideLineModal,
    backDropOpen,
  } = props;
  const useStyles = recordPageFooter();
  const classes = useStyles();

  return (
    <Box
      className={classes.footer}
      sx={{ position: "fixed", bottom: 0, width: "100%" }}
    >
      <Grid container p={1}>
        <Grid item xs={6}>
          <FooterPageNumber {...{ selectedIndex, totalPages }} />
        </Grid>
        <Grid item xs={6}>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="flex-end"
          >
            <Button
              disabled={mergeAllParaDisabled.state || isVerifyCompleted.state}
              variant="outlined"
              size="small"
              sx={primaryButtonHover}
              onClick={() => {
                backDropOpen.update(true);
                callMergeAllPara();
              }}
              // onClick={() => {
              //   setConfirmModalData(
              //     MergeParagraphIcon,
              //     "Merge all paragraphs",
              //     "Are you sure you want to merge all paragraphs?",
              //     "Yes, merge all",
              //     "Cancel, keep paragraphs",
              //     () => {
              //       callMergeAllPara();
              //     },
              //     null
              //   );
              // }}
            >
              <Typography variant="font15b">Merge all paragraphs</Typography>
            </Button>
            <Button
              disabled={!isVerifyCompleted.state}
              sx={primaryButtonHover}
              variant="outlined"
              size="small"
              color="primary"
              onClick={callGroupChapters}
            >
              <Typography variant="font15b">Group pages</Typography>
            </Button>
            <Tooltip title="Guideline info">
              <IconButton
                size="small"
                onClick={(e) => {
                  openGuideLineModal.update(true);
                }}
              >
                <img src={Info} className={classes.infoBtn} alt="Info" />
              </IconButton>
            </Tooltip>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

export default VerifyPageFooter;
