import React from "react";
import {
  Grid,
  Stack,
  Typography,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Divider,
  Box,
  TableContainer,
  Table,
  TableBody,
  IconButton,
  Tooltip,
} from "@mui/material";
import { rowHoverClickStyle } from "components/common/Styles";
import NoDataFound from "components/common/NoDataFound";
import { COLORS } from "constants";
import PlayBtn from "assets/images/play-blue.png";
import PauseBtn from "assets/images/pauseWave.png";
import DownloadBtn from "assets/images/download_cloud.png";
import UploadBtn from "assets/images/upload_cloud.png";
import copyAudioBtn from "assets/images/copy-audio.png";
import { copyChapterAudio } from "service/BookService";
import { UNEXPECTED_ERROR_MESSAGE } from "constants/otherConstant";
import { useStateUpdate } from "UseStateUpdate";
import Toast from "./Toast";
import { bookRecordedPage } from "./Styles";

const headCells = [
  { id: "number", label: "#" },
  { id: "title", label: "TITLE" },
  { id: "pages", label: "PAGES" },
];

function ChapterTable(props) {
  const {
    inOptimization,
    isCompleted,
    chapters,
    selectedChapter,
    isWavePlaying,
    currentUrlToPlay,
    pauseAudio,
    playAudio,
    handleDownload,
    currentChapterToUpload,
    openAudioBookModal,
    isWaveLoading,
    fetchBookDetails,
  } = props;
  const classes = rowHoverClickStyle();
  const severity = useStateUpdate("success");
  const toastMessage = useStateUpdate("");
  const toastOpen = useStateUpdate(false);

  const handlePlayAudio = (audioUrl) => {
    if (isWavePlaying.state) {
      pauseAudio();
      if (currentUrlToPlay.state !== audioUrl) {
        currentUrlToPlay.update(audioUrl);
      }
    } else {
      if (currentUrlToPlay.state === audioUrl) {
        playAudio();
      } else {
        currentUrlToPlay.update(audioUrl);
      }
    }
  };

  const handleCopyAudio = async (bookId, chapterId, audioUrl) => {
    let body = {
      audioUrl: audioUrl,
    };
    let response = await copyChapterAudio(bookId, chapterId, body);
    if (response.status === 200) {
      fetchBookDetails();
      calltoast("success", "Optimized audio added");
    } else {
      calltoast("error", UNEXPECTED_ERROR_MESSAGE);
    }
  };

  const calltoast = async (severityIs, messageIs) => {
    severity.update(severityIs);
    toastMessage.update(messageIs);
    toastOpen.update(true);
  };

  const renderPlayButton = (audioUrl) => {
    return (
      <Tooltip title="Play">
        <IconButton
          disabled={isWaveLoading.state}
          color="primary"
          onClick={(e) => {
            handlePlayAudio(audioUrl);
          }}
        >
          <img
            src={
              isWavePlaying.state && currentUrlToPlay.state === audioUrl
                ? PauseBtn
                : PlayBtn
            }
            style={bookRecordedPage.button}
            alt="play"
          />
        </IconButton>
      </Tooltip>
    );
  };

  const renderCopyButton = (bookId, chapterId, audioUrl) => {
    return (
      <Tooltip title="Copy audio from left">
        <IconButton
          disabled={isWaveLoading.state}
          color="primary"
          onClick={(e) => {
            handleCopyAudio(bookId, chapterId, audioUrl);
          }}
        >
          <img
            src={copyAudioBtn}
            style={bookRecordedPage.button}
            alt="Copy audio from left"
          />
        </IconButton>
      </Tooltip>
    );
  };

  const handleUploadOptimizedAudio = (chapter) => {
    currentChapterToUpload.update(chapter);
    openAudioBookModal.update(true);
  };

  const renderUploadButton = (chapter) => {
    return (
      <Tooltip title="Upload optimized audio">
        <IconButton
          color="primary"
          onClick={(e) => {
            handleUploadOptimizedAudio(chapter);
          }}
        >
          <img src={UploadBtn} style={bookRecordedPage.button} alt="upload" />
        </IconButton>
      </Tooltip>
    );
  };

  const renderDownloadButton = (audioUrl) => {
    return (
      <Tooltip title="Download audio">
        <IconButton
          color="primary"
          onClick={(e) => {
            handleDownload(audioUrl);
          }}
        >
          <img
            src={DownloadBtn}
            style={bookRecordedPage.button}
            alt="download"
          />
        </IconButton>
      </Tooltip>
    );
  };

  const chapteRows = chapters.state.map((chapter, index) => (
    <TableRow
      key={chapter.chapterId}
      hover
      classes={{ hover: classes.hover }}
      onClick={() => {
        selectedChapter.update(chapter);
      }}
    >
      <TableCell align="center">
        <Typography variant="font15">{index + 1}</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography variant="font15">{chapter.chapterName}</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography variant="font15">{`${chapter.startPageNumber ?? "-"} - ${
          chapter.endPageNumber ?? "-"
        }`}</Typography>
      </TableCell>
      {inOptimization && (
        <TableCell align="center">
          <Stack direction="row" justifyContent="center" alignItems="center">
            {renderPlayButton(chapter.audioUrl)}
            {renderDownloadButton(chapter.audioUrl)}
          </Stack>
        </TableCell>
      )}
      <TableCell align="center">
        <Stack direction="row" justifyContent="center" alignItems="center">
          {chapter.optimizedAudioUrl
            ? renderPlayButton(chapter.optimizedAudioUrl)
            : inOptimization &&
              renderCopyButton(
                chapter.bookId,
                chapter.chapterId,
                chapter.audioUrl
              )}
          {inOptimization && renderUploadButton(chapter)}
          {isCompleted && renderDownloadButton(chapter.optimizedAudioUrl)}
        </Stack>
      </TableCell>
    </TableRow>
  ));

  let tableContent;

  if (chapters.state.length === 0) {
    tableContent = <NoDataFound colSpanValue={inOptimization ? "5" : "4"} />;
  } else {
    tableContent = chapteRows;
  }

  return (
    <Grid container item xs={12}>
      <Paper variant="outlined" sx={{ width: "100%" }}>
        <Box sx={{ width: { xs: "90vw", sm: "100%" } }}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align="center">
                      <Typography
                        variant="font15b"
                        color={COLORS.primary}
                        noWrap
                      >
                        {headCell.label}
                      </Typography>
                    </TableCell>
                  ))}
                  {inOptimization && (
                    <TableCell align="center">
                      <Typography
                        variant="font15b"
                        color={COLORS.primary}
                        noWrap
                      >
                        AUDIO
                      </Typography>
                    </TableCell>
                  )}
                  <TableCell align="center">
                    <Typography variant="font15b" color={COLORS.primary} noWrap>
                      OPTIMIZED AUDIO
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{tableContent}</TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Divider />
      </Paper>
      <Toast
        severity={severity.state}
        toastMessage={toastMessage.state}
        toastOpen={toastOpen}
      />
    </Grid>
  );
}

export default ChapterTable;
