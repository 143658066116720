import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import MergeParagraphUpwards from "assets/images/merge-upwards.png";
import MergeParagraphDownwardsLineBreak from "assets/images/merge-downwards-linebreak.png";
import { MERGE_DIRECTIONS } from "constants";
import {
  verifyRightSideButtons,
  iconButtonImage,
} from "components/common/Styles";

function MergeUpButton(props) {
  const {
    index,
    getPrevPageLastPara,
    pages,
    selectedIndex,
    pageContentsToEdit,
    callMergePara,
    setConfirmModalData,
    content,
    calltoast,
    openConfirmModal,
    backDropOpen,
    lineBreak,
  } = props;
  const classes = verifyRightSideButtons();
  const classes2 = iconButtonImage();

  const handleMergeParagraph = async (content, index) => {
    const getPrevParaId = async () => {
      if (index === 0) {
        return await getPrevPageLastPara(
          pages.state[selectedIndex.state - 1].pageId
        );
      }
      return pageContentsToEdit.state[index - 1].paragraphId;
    };

    const mergeWithParagraphId = await getPrevParaId();
    if (mergeWithParagraphId) {
      const mergeDirection = MERGE_DIRECTIONS.up;
      callMergePara(
        content.paragraphId,
        mergeWithParagraphId,
        mergeDirection,
        lineBreak
      );
    } else {
      openConfirmModal.update(false);
      calltoast(
        "error",
        "The paragraph was not found. Please check the previous page."
      );
    }
  };

  // const handleMergeClick = (content, index) => {
  //   setConfirmModalData(
  //     MergeParagraphIcon,
  //     "Merge paragraphs",
  //     `Are you sure you want to merge paragraphs?`,
  //     "Yes, merge paragraphs",
  //     "Cancel, keep paragraphs",
  //     async () => {
  //       await handleMergeParagraph(content, index);
  //     },
  //     null
  //   );
  // };

  return (
    <Tooltip
      title={`${
        lineBreak
          ? "Merge with previous paragraph with line break"
          : "Merge with previous paragraph"
      }`}
      placement="top"
    >
      <IconButton
        color="primary"
        onClick={() => {
          backDropOpen.update(true);
          handleMergeParagraph(content, index);
        }}
        className={classes.button}
      >
        <img
          src={
            lineBreak ? MergeParagraphDownwardsLineBreak : MergeParagraphUpwards
          }
          className={classes2.buttonImage}
          alt="merge para"
        />
      </IconButton>
    </Tooltip>
  );
}

export default MergeUpButton;
