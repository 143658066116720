import React, { Fragment, useEffect } from "react";
import { Box, Button, Grid, Stack, Tooltip, Typography } from "@mui/material";

import { MOBILE_MAX_WIDTH } from "constants/otherConstant";
import { COLORS, BOOK_STATUS_CODES as BookStatusCodes } from "constants";
import { useStateUpdate } from "UseStateUpdate";

import {
  ConfirmModal,
  WarningModal,
  SelectRetailSampleModal,
  AudioUploadModal,
} from "components/modals";
import { checkBookStatus } from "utils/Checkbookstatus";
import { useIsAuthor, useIsAudioEngineer } from "utils/GetUserDetail";
import AccessDenied from "components/common/AccessDenied";
import { updateBookStatus, getChaptersWithAudio } from "service/BookService";
import { useNavigate, useParams } from "react-router-dom";
import Toast from "components/common/Toast";
import ChapterTable from "components/common/ChapterTable";
import WaveSurfer from "wavesurfer.js";
import CursorPlugin from "wavesurfer.js/dist/plugin/wavesurfer.cursor";
import { getAudioPlayTime } from "utils/DateTimeUtils";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import {
  BookName,
  PageImage,
  PageDetails,
  ConfirmButton,
  SelectRetailSampleButton,
  RejectButton,
} from "./ChildComponents";
import { getAudioUrl } from "utils/GetUrl";
import { updateProject } from "service/ProjectService";

const isMobile = window.innerWidth <= MOBILE_MAX_WIDTH;
let waveSurfer;

function BooksRecorded({ status }) {
  const { bookId } = useParams();
  let navigate = useNavigate();
  const isAuthor = useIsAuthor();
  const isAudioEngineer = useIsAudioEngineer();
  const openConfirmModal = useStateUpdate(false);
  const openSelectRetailSampleModal = useStateUpdate(false);
  const confirmModalData = useStateUpdate({
    icon: null,
    title: null,
    message: null,
    confirmButton: null,
    cancelButton: null,
    onConfirm: null,
    onCancel: null,
  });

  const openWarningModal = useStateUpdate(false);
  const warningModalMessage = useStateUpdate("");
  const bookDetails = useStateUpdate(null);
  const navigateTo = useStateUpdate(null);
  const selectedChapter = useStateUpdate(null);
  const chapters = useStateUpdate(null);
  const isWavePlaying = useStateUpdate(false);
  const isWaveLoading = useStateUpdate(false);
  const isFetching = useStateUpdate(true);
  const currentUrlToPlay = useStateUpdate("");
  const openAudioBookModal = useStateUpdate(false);
  const currentChapterToUpload = useStateUpdate({});
  const severity = useStateUpdate("success");
  const toastMessage = useStateUpdate("");
  const toastOpen = useStateUpdate(false);
  const currentWaveTime = useStateUpdate("00:00:00");
  const isConfirmBtnDisable = useStateUpdate(true);

  useEffect(() => {
    if (isMobile) {
      openWarningModal.update(true);
      warningModalMessage.update(
        "You cannot use this page on this device. Please use a desktop instead."
      );
      navigateTo.update("/dashboard");
    } else {
      !isAuthor && fetchBookChaptersWithAudio();
    }
    return () => {
      waveSurfer?.destroy();
    };
  }, []);

  useEffect(() => {
    if (bookDetails.state) {
      const bookStatus = checkBookStatus(
        status,
        bookDetails.state,
        bookDetails.state.chapters.length
      );

      openWarningModal.update(bookStatus.isOpenModal);
      warningModalMessage.update(bookStatus.warningMessage);
      navigateTo.update(bookStatus.navigatePath);

      if (!bookStatus.isOpenModal) {
        selectedChapter.update(bookDetails.state.chapters[0]);
        chapters.update(bookDetails.state.chapters);
        isFetching.update(false);
      }
    }
  }, [bookDetails.state]);

  useEffect(() => {
    chapters.state && checkOptimizedAudio();
  }, [chapters.state]);

  useEffect(() => {
    waveSurfer?.destroy();
    isWavePlaying.update(false);
    currentWaveTime.update("00:00:00");
  }, [selectedChapter.state]);

  useEffect(() => {
    if (currentUrlToPlay.state) {
      waveSurfer?.destroy();
      loadWaveSurfer(
        getAudioUrl(currentUrlToPlay.state) + "?t=" + new Date().getTime()
      );
    }
  }, [currentUrlToPlay.state]);

  const fetchBookChaptersWithAudio = async () => {
    const result = await getChaptersWithAudio(bookId);
    if (result.status === 200) {
      const { data } = result.data;
      bookDetails.update(data);
    }
  };

  const callUpdateBookStatus = async (statusCode) => {
    const body = { statusCode: statusCode };
    const result = await updateBookStatus(bookId, body);
    if (result.status === 200) {
      if (statusCode === BookStatusCodes.completed) {
        let newProjectDetails = {
          isComplete: true,
        };

        await updateProject(bookDetails.state.projectId, newProjectDetails);
      }
      isAudioEngineer
        ? navigate(`/dashboard`)
        : navigate(`/projects/${bookDetails.state.projectId}`);
    }
  };

  const checkOptimizedAudio = () => {
    let btnDisabled = false;
    chapters.state.forEach((chapter) => {
      if (chapter.optimizedAudioUrl === "") btnDisabled = true;
    });
    if (
      status === BookStatusCodes.readyForProduction &&
      (!bookDetails.state?.retailSample ||
        bookDetails.state?.retailSample === "processing")
    ) {
      btnDisabled = true;
    }
    isConfirmBtnDisable.update(btnDisabled);
  };

  const setConfirmModalData = (
    icon,
    title,
    message,
    confirmButton,
    cancelButton,
    onConfirm,
    onCancel
  ) => {
    confirmModalData.update({
      icon,
      title,
      message,
      confirmButton,
      cancelButton,
      onConfirm,
      onCancel,
    });
    openConfirmModal.update(true);
  };

  const playAudio = (e) => {
    waveSurfer.play();
    isWavePlaying.update(true);
  };

  const pauseAudio = () => {
    waveSurfer?.pause();
    isWavePlaying.update(false);
  };

  const loadWaveSurfer = (urlIs) => {
    isWaveLoading.update(true);
    waveSurfer = WaveSurfer.create({
      container: document.querySelector("#waveform"),
      barWidth: 3,
      barRadius: 3,
      barGap: 2,
      barMinHeight: 1,
      cursorWidth: 1,
      backend: "WebAudio",
      progressColor: COLORS.primary,
      responsive: true,
      waveColor: "#C4C4C4",
      cursorColor: "transparent",
      plugins: [
        CursorPlugin.create({
          showTime: true,
          opacity: 1,
          customShowTimeStyle: {
            "background-color": "#000",
            color: "#fff",
            padding: "2px",
            "font-size": "10px",
          },
          customWaveColor: function (percents) {
            return percents <= 50 ? "#ff0000" : "#00ff00";
          },
        }),
      ],
    });
    waveSurfer.load(urlIs);
    waveSurfer.on("ready", () => {
      playAudio();
      isWaveLoading.update(false);
    });
    waveSurfer.on("finish", () => {
      isWavePlaying.update(false);
    });
    waveSurfer.on("audioprocess", handleAudioProcess);
  };

  const handleAudioProcess = () => {
    const currentTime = waveSurfer.getCurrentTime();
    currentWaveTime.update(getAudioPlayTime(currentTime));
  };

  const handleDownload = (audioUrl) => {
    const url = getAudioUrl(audioUrl) + "?t=" + new Date().getTime();
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "");
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const handlePlayButton = () => {
    if (isWavePlaying.state) {
      pauseAudio();
    } else {
      if (
        currentUrlToPlay.state === selectedChapter.state.audioUrl ||
        currentUrlToPlay.state === selectedChapter.state.optimizedAudioUrl ||
        currentUrlToPlay.state === bookDetails.state.retailSample
      ) {
        playAudio();
      } else {
        currentUrlToPlay.update(
          status === BookStatusCodes.bookRecorded
            ? selectedChapter.state.audioUrl
            : selectedChapter.state.optimizedAudioUrl
        );
      }
    }
  };

  return isAuthor ? (
    <AccessDenied />
  ) : (
    <Box pt={5} px={10}>
      <ConfirmModal {...{ openConfirmModal, confirmModalData }} />
      <WarningModal
        {...{ openWarningModal, warningModalMessage, navigateTo }}
      />
      <AudioUploadModal
        open={openAudioBookModal}
        bookId={currentChapterToUpload.state.bookId}
        chapterId={currentChapterToUpload.state.chapterId}
        fetchDetails={fetchBookChaptersWithAudio}
        {...{ severity, toastMessage, toastOpen }}
      />
      <SelectRetailSampleModal
        open={openSelectRetailSampleModal}
        {...{ bookId, fetchBookChaptersWithAudio }}
      />

      <Grid container spacing={4} mb={5}>
        <BookName bookName={bookDetails.state?.bookName} />
        {!isFetching.state && (
          <Fragment>
            <Grid container item xs={10}>
              <PageImage
                pageImageUrl={selectedChapter?.state?.pages[0]?.pageImageUrl}
                {...{ bookId }}
              />
              <PageDetails
                {...{
                  selectedChapter,
                  isWaveLoading,
                  isWavePlaying,
                  currentWaveTime,
                  handlePlayButton,
                }}
              />
            </Grid>

            <Grid
              item
              xs={2}
              container
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <Stack spacing={1}>
                <SelectRetailSampleButton
                  {...{
                    status,
                    bookDetails,
                    currentUrlToPlay,
                    handlePlayButton,
                    isWavePlaying,
                    openSelectRetailSampleModal,
                    fetchBookChaptersWithAudio,
                  }}
                />
                {bookDetails.state.compressedFileUrl ||
                status !== BookStatusCodes.completed ? (
                  <ConfirmButton
                    {...{
                      status,
                      isConfirmBtnDisable,
                      isWavePlaying,
                      pauseAudio,
                      handleDownload,
                      bookDetails,
                      setConfirmModalData,
                      callUpdateBookStatus,
                    }}
                  />
                ) : (
                  <Tooltip title="Click to refresh.">
                    <Button variant="text" onClick={fetchBookChaptersWithAudio}>
                      <AutorenewIcon />
                      <Typography variant="font15b" noWrap>
                        Generating zip file
                      </Typography>
                    </Button>
                  </Tooltip>
                )}
                <RejectButton
                  {...{
                    status,
                    isWavePlaying,
                    pauseAudio,
                    setConfirmModalData,
                    callUpdateBookStatus,
                  }}
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              {!isFetching.state && (
                <ChapterTable
                  inOptimization={status === BookStatusCodes.bookRecorded}
                  isCompleted={status === BookStatusCodes.completed}
                  fetchBookDetails={fetchBookChaptersWithAudio}
                  {...{
                    chapters,
                    selectedChapter,
                    isWavePlaying,
                    currentUrlToPlay,
                    pauseAudio,
                    playAudio,
                    handleDownload,
                    currentChapterToUpload,
                    openAudioBookModal,
                    isWaveLoading,
                  }}
                />
              )}
            </Grid>
          </Fragment>
        )}
      </Grid>
      <Toast
        severity={severity.state}
        toastMessage={toastMessage.state}
        toastOpen={toastOpen}
      />
    </Box>
  );
}

export default BooksRecorded;
