import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import MergeParagraphDownwards from "assets/images/merge-downwards.png";
import MergeParagraphUpwardsLineBreak from "assets/images/merge-upwards-linebreak.png";
import { MERGE_DIRECTIONS } from "constants";
import {
  verifyRightSideButtons,
  iconButtonImage,
} from "components/common/Styles";

function MergeDownButton(props) {
  const {
    index,
    content,
    callMergePara,
    pageContentsToEdit,
    selectedIndex,
    pages,
    getNextPageFirstPara,
    setConfirmModalData,
    calltoast,
    openConfirmModal,
    backDropOpen,
    lineBreak,
  } = props;
  const classes = verifyRightSideButtons();
  const classes2 = iconButtonImage();

  // const handleMergeClick = (content, index) => {
  //   setConfirmModalData(
  //     MergeParagraphIcon,
  //     "Merge paragraphs",
  //     `Are you sure you want to merge paragraphs?`,
  //     "Yes, merge paragraphs",
  //     "Cancel, keep paragraphs",
  //     async () => {
  //       await handleMergeParagraph(content, index);
  //     },
  //     null
  //   );
  // };

  const handleMergeParagraph = async (content, index) => {
    const getNextParaId = async () => {
      if (index === pageContentsToEdit.state.length - 1) {
        return await getNextPageFirstPara(
          pages.state[selectedIndex.state + 1].pageId
        );
      }
      return pageContentsToEdit.state[index + 1].paragraphId;
    };

    const mergeWithParagraphId = await getNextParaId();
    if (mergeWithParagraphId) {
      const mergeDirection = MERGE_DIRECTIONS.down;
      callMergePara(
        content.paragraphId,
        mergeWithParagraphId,
        mergeDirection,
        lineBreak
      );
    } else {
      openConfirmModal.update(false);
      calltoast(
        "error",
        "The paragraph was not found. Please check the next page."
      );
    }
  };

  return (
    <Tooltip
      title={`${
        lineBreak
          ? "Merge with next paragraph with line break"
          : "Merge with next paragraph"
      }`}
      placement="top"
    >
      <IconButton
        color="primary"
        onClick={() => {
          backDropOpen.update(true);
          handleMergeParagraph(content, index);
        }}
        className={classes.button}
      >
        <img
          src={
            lineBreak ? MergeParagraphUpwardsLineBreak : MergeParagraphDownwards
          }
          className={classes2.buttonImage}
          alt="merge para"
        />
      </IconButton>
    </Tooltip>
  );
}

export default MergeDownButton;
